import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  SIGNUP,
  FORGOTPASSWORD,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  SETTINGS, 
  CUSTOMERS,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,ORDERDETAIL,AREAOFDELIVERY,MANAGEPRODUCTPRICE,NOTIFICATION,PROFILE
} from './settings/constants';
import AuthProvider, { AuthContext } from './context/auth';
import './theme/custom_admin.css';
import './theme/pagination.css';
import { InLineLoader } from './components/InlineLoader/InlineLoader';
const Products = lazy(() => import('./containers/Products/Products'));
const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Category = lazy(() => import('./containers/Category/Category'));
const Orders = lazy(() => import('./containers/Orders/Orders'));
const Order_Detail = lazy(() => import('./containers/Orders/Order_Detail'));
const Area_of_Delivery = lazy(() => import('./containers/Area_of_Delivery/Area_of_Delivery'));
const Manage_Product_Price = lazy(() => import('./containers/Manage_Product_Price/Manage_Product_Price'));
const Notification = lazy(() => import('./containers/Notification/Notification'));
const Settings = lazy(() => import('./containers/Settings/Settings'));
const Profile = lazy(() => import('./containers/Profile/Profile'));
const SiteSettingForm = lazy(() =>
  import('./containers/SiteSettingForm/SiteSettingForm')
);
const StaffMembers = lazy(() =>
  import('./containers/StaffMembers/StaffMembers')
);
const Customers = lazy(() => import('./containers/Customers/Customers'));
const Coupons = lazy(() => import('./containers/Coupons/Coupons'));
const Login = lazy(() => import('./containers/Login/Login'));
const Forgotpwd = lazy(() => import('./containers/Login/forgot_password'));
const Signup = lazy(() => import('./containers/Signup/signup'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERDETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
              <Order_Detail />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={AREAOFDELIVERY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
              <Area_of_Delivery />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MANAGEPRODUCTPRICE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
              <Manage_Product_Price />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={NOTIFICATION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
              <Notification />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PROFILE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
              <Profile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={SIGNUP}>
            <Signup />
          </Route>
          <Route path={FORGOTPASSWORD}>
            <Forgotpwd />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
