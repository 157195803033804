// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const FORGOTPASSWORD = '/forgot_password'; 
export const SIGNUP = '/signup';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const ORDERDETAIL = '/orderdetail';
export const AREAOFDELIVERY = '/area_of_delivery';
export const MANAGEPRODUCTPRICE= '/manage_product_price'
export const NOTIFICATION ='/notification'
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const PROFILE = '/profile'
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '₹';
