import React from 'react';
import axios from 'axios';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem('pickbazar_token');
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
}; 

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  function authenticate({ username, password }, cb) {
    var formData = new FormData();
    formData.append("mobile_no", `${username}`);
    formData.append("password", `${password}`);
    axios.post("http://veggi-app-v2.adriit.com/api/vendor/login",formData)
      .then(result => {
        if(result.data.status===1){
          makeAuthenticated(true);
          localStorage.setItem('pickbazar_token', result.data.data.token);
          localStorage.setItem('user_info', JSON.stringify(result.data.data.user_detail)); 
          setTimeout(cb, 100); // fake async
        }else{
          localStorage.setItem('display_alert', '1');
          localStorage.setItem('alert_msg', 'Please Enter Valid Crendentials.');
          makeAuthenticated(false);
          setTimeout(cb, 100); // fake async
        }
      }).catch((error) =>{
        // alert("Something Went Wrong");
        localStorage.setItem('display_alert', '1');
        localStorage.setItem('alert_msg', 'Something Went Wrong.');
        makeAuthenticated(false);
        setTimeout(cb, 100); // fake async
      });
  }
  function signout(cb) {
    axios.get("http://veggi-app-v2.adriit.com/api/vendor/logout",{ headers: { "Authorization": "Bearer " + localStorage.getItem('pickbazar_token') } })
      .then(result => {
        makeAuthenticated(false);
        localStorage.removeItem('pickbazar_token');
        setTimeout(cb, 100);
      }).catch((error) =>{
        makeAuthenticated(false);
        localStorage.removeItem('pickbazar_token');
        setTimeout(cb, 100);
      });
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
